const styles = {
  root: {
    paddingTop: '7em',
    maxWidth: '1200px'
  },
  result: {
    margin: '5em'
  },
  breadcrumb: {
    marginLeft: '5em',
    margin: '4em'
  },
  listing: {
    padding: '5em'
  },
  address: {
    margin: '3em'
  }
}

export default styles