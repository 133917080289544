const styles = {
  root: {
    paddingTop: '12em',
    padding: '3em'
  },
  page: {
    padding: '5em'
  }
}

export default styles